// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import { useForm, Controller } from "react-hook-form";

import { useNavigate } from "react-router-dom";
import { apiPost } from "utils/AxiosService";
import { useMaterialUIController, setSnackbarState } from "context";

function Basic() {
  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await apiPost("/admin/login", data);
      console.log("into the signin" + response);
      if (response.statusCode === 200) {
        const { accessToken, refreshToken } = response.data;

        // Store tokens in localStorage
        localStorage.setItem("token", accessToken);
        localStorage.setItem("refreshToken", refreshToken);

        console.log("Login successful");
        console.log("Access Token:", accessToken);
        console.log("Refresh Token:", refreshToken);
        setSnackbarState(dispatch, {
          open: true,
          message: response?.message,
          severity: "success",
        });
        // Navigate to the dashboard
        navigate("/dashboard");
      } else {
        // alert("Invalid credentials");
        setSnackbarState(dispatch, {
          open: true,
          message: "Invalid credentials",
          severity: "error",
        });
        console.log("Invalid username or password");
      }
    } catch (error) {
      // alert("An error occurred while logging in.");
      console.error("Error during login:", error);
    }
  };
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>

        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
            <MDBox mb={2}>
              <Controller
                name="username"
                control={control}
                defaultValue=""
                rules={{
                  required: "username is required",
                }}
                render={({ field }) => (
                  <>
                    <MDInput {...field} type="text" label="Username" fullWidth />
                    {errors.username && (
                      <span style={{ color: "red" }}>{errors.username.message}</span>
                    )}
                  </>
                )}
              />
            </MDBox>
            <MDBox mb={2}>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  required: "password is required",
                }}
                render={({ field }) => (
                  <>
                    <MDInput {...field} type="text" label="Password" fullWidth />
                    {errors.password && (
                      <span style={{ color: "red" }}>{errors.password.message}</span>
                    )}
                  </>
                )}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                Sign In
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
