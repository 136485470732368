import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import dummyImage from "assets/images/dummyImageUpload.png";

import "./fileupload.css";

const FileUpload = (props) => {
  const { onChange, url } = props;
  const [image, setImage] = useState("");

  useEffect(() => {
    if (!url) return;
    setImage(url);
  }, [url]);

  const onImageSelect = (event) => {
    let file = event.target.files[0];
    if (!file) return;
    const url = URL.createObjectURL(file);
    setImage(url);
    onChange(file ?? null);
  };

  return (
    <div className="file-upload-container">
      <input type="file" accept="image/*" onChange={onImageSelect} />
      <div className="content">
        {image && (
          <>
            <img src={image}></img>
            <p>Change Image</p>
          </>
        )}

        {!image && (
          <>
            <img src={dummyImage}></img>
            <p>Upload Image</p>
          </>
        )}
      </div>
    </div>
  );
};

FileUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  url: PropTypes.any,
};

export default FileUpload;
