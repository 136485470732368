import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import CampaignSummaryCard from "../CampaignSummaryCard";
import { apiGet } from "utils/AxiosService";
import { useSearchParams } from "react-router-dom";
import { useMaterialUIController, setSnackbarState, setLoaderState } from "context";
import { useParams } from "react-router-dom";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

export default function CampaignSummary() {
  const param = useParams();
  const [searchParams] = useSearchParams();
  const [controller, dispatch] = useMaterialUIController();

  const { editCampaignId } = controller;
  const [campaignSummaryData, seCampaignSummaryData] = useState([]);
  useEffect(() => {
    getAllSummaryByCampaignId();
  }, [param.id]);

  const getAllSummaryByCampaignId = async () => {
    setLoaderState(dispatch, true);
    const id = searchParams.get("id");
    const apiResponse = await apiGet("/campaign/getAllSummary/" + id);
    seCampaignSummaryData(apiResponse?.data);
    setLoaderState(dispatch, false);
  };
  const campaignSummary = {
    campaignName: "Example Campaign",
    totalSteps: 1000,
    achievedTargetSteps: 750,
    totalSG: 500,
  };
  return (
    <DashboardLayout>
      <DashboardNavbar style={{ zIndex: 300 }} />
      <MDBox pt={1} mt={5} mb={5} sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ width: "100%" }}>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            <MDTypography variant="h6" color="white">
              Campaign Summary
            </MDTypography>
          </MDBox>

          <CampaignSummaryCard campaignSummaryData={campaignSummaryData} />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
