// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useForm, Controller } from "react-hook-form";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { FormHelperText } from "@mui/material";
import Box from "@mui/material/Box";

//import { showToast } from "layouts/toaster/CustomizedSnackbars";

// Authentication layout components

// Images
import { apiPost } from "utils/AxiosService";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CustomizedSnackbars from "layouts/toaster/CustomizedSnackbars";
import PropTypes from "prop-types";
import FileUpload from "components/ImageUpload";
import { useMaterialUIController, setSnackbarState, setLoaderState } from "context";

function AddNewCampaig(props) {
  const [status, setStatus] = useState("");
  const [controller, dispatch] = useMaterialUIController();

  // eslint-disable-next-line react/prop-types
  const { handleCampaignNavigate } = props;
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const [imageSelected, setImageSelected] = useState(null);

  const onSubmit = (data) => {
    const formData = new FormData();
    let requestData = {
      ...data,
      achievedTargetSteps: 0,
      completed: false,
    };
    delete requestData.imageFile;
    for (const key in requestData) {
      if (Object.hasOwnProperty.call(requestData, key)) {
        const value = requestData[key];
        formData.append(key, value);
      }
    }

    if (imageSelected !== null) {
      formData.append("imageFile", imageSelected);
    }

    createCampaign(formData);
  };

  const createCampaign = async (requestData) => {
    try {
      setLoaderState(dispatch, true);
      const apiData = await apiPost("/campaign/create", requestData);
      if (apiData.statusCode === 200) {
        setLoaderState(dispatch, false);
        setSnackbarState(dispatch, {
          open: true,
          message: apiData?.message,
          severity: "success",
        });
        reset();
        navigate("/campaigns");
      } else {
        if (apiData.statusCode === 400) {
          setLoaderState(dispatch, false);
          setSnackbarState(dispatch, {
            open: true,
            message: apiData?.message,
            severity: "error",
          });
        }
      }
    } catch (error) {
      setLoaderState(dispatch, false);
      setStatus("error");
    }
  };

  return (
    <>
      <MDBox
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
        px={5}
        sx={{ width: "100%" }}
      >
        <MDBox sx={{ Padding: 2, borderBottom: 3 }}>
          <MDTypography>Titile & Steps</MDTypography>
          <MDBox mb={2}>
            <Controller
              name="campaignTitle"
              control={control}
              render={({ field }) => (
                <>
                  <MDInput
                    {...field}
                    type="text"
                    label="Campaign Title"
                    variant="standard"
                    fullWidth
                    error={!!errors.campaignTitle}
                    helperText={
                      errors.campaignTitle && (
                        <span style={{ color: "red" }}>{errors.campaignTitle.message}</span>
                      )
                    }
                  />
                </>
              )}
              rules={{ required: "Campaign Title is required" }}
            />
          </MDBox>
          <MDBox mb={2}>
            <Controller
              name="arabicCampaignTitle"
              control={control}
              render={({ field }) => (
                <>
                  <MDInput
                    {...field}
                    type="text"
                    label="Campaign Title (lang - Arabic)"
                    variant="standard"
                    fullWidth
                  />
                </>
              )}
            />
          </MDBox>
          <MDBox mb={2}>
            <Controller
              name="totalSteps"
              control={control}
              render={({ field }) => (
                <>
                  <MDInput
                    {...field}
                    type="number"
                    label="Campaign Target"
                    variant="standard"
                    fullWidth
                    error={!!errors.totalSteps}
                    helperText={
                      errors.totalSteps && (
                        <span style={{ color: "red" }}>{errors.totalSteps.message}</span>
                      )
                    }
                  />
                </>
              )}
              rules={{
                required: "Campaign Target is required",
                validate: (value) =>
                  value >= 0 || "Campaign Target must be greater than or equal to 0",
              }}
            />
          </MDBox>
          <MDBox mb={4}>
            <Controller
              name="target4SG"
              control={control}
              render={({ field }) => (
                <>
                  <MDInput
                    {...field}
                    type="number"
                    label="Steps to Achieve"
                    variant="standard"
                    fullWidth
                    error={!!errors.target4SG}
                    helperText={
                      errors.target4SG && (
                        <span style={{ color: "red" }}>{errors.target4SG.message}</span>
                      )
                    }
                  />
                </>
              )}
              rules={{
                required: "Steps to Achieve is required",
                validate: (value) =>
                  value >= 0 || "Steps to Achieve must be greater than or equal to 0",
              }}
            />
          </MDBox>
        </MDBox>
        <MDBox mt={3} sx={{ Padding: 2, borderBottom: 3 }}>
          <MDTypography>Description</MDTypography>
          <MDBox mb={2} mt={1}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <>
                  <MDInput
                    {...field}
                    type="text"
                    label="Camapign  Description (Lang - Eng)"
                    variant="standard"
                    fullWidth
                    error={!!errors.description}
                    helperText={
                      errors.description && (
                        <span style={{ color: "red" }}>{errors.description.message}</span>
                      )
                    }
                  />
                </>
              )}
              rules={{ required: "Campaign Description is required" }}
            />
          </MDBox>
          <MDBox mb={2}>
            <Controller
              name="description2"
              control={control}
              render={({ field }) => (
                <>
                  <MDInput
                    {...field}
                    type="text"
                    label="Camapign  Description 2 (Lang - Eng)"
                    variant="standard"
                    fullWidth
                  />
                </>
              )}
            />
          </MDBox>
          <MDBox mb={2}>
            <Controller
              name="arabicDescription1"
              control={control}
              render={({ field }) => (
                <>
                  <MDInput
                    {...field}
                    type="text"
                    label="Camapign  Description  (Lang - Arabic)"
                    variant="standard"
                    fullWidth
                  />
                </>
              )}
            />
          </MDBox>
          <MDBox mb={4}>
            <Controller
              name="arabicDescription2"
              control={control}
              render={({ field }) => (
                <>
                  <MDInput
                    {...field}
                    type="text"
                    label="Camapign  Description 2 (Lang - Arabic)"
                    variant="standard"
                    fullWidth
                  />
                </>
              )}
            />
          </MDBox>
        </MDBox>

        <MDBox mt={3} sx={{ Padding: 2, borderBottom: 3 }}>
          <MDTypography>Home Text & Summary</MDTypography>
          <MDBox mb={2}>
            <Controller
              name="homeText1En"
              control={control}
              render={({ field }) => (
                <>
                  <MDInput
                    {...field}
                    type="text"
                    label="Home Text (Lang - Eng)"
                    variant="standard"
                    fullWidth
                  />
                </>
              )}
            />
          </MDBox>
          <MDBox mb={2}>
            <Controller
              name="homeText2En"
              control={control}
              render={({ field }) => (
                <>
                  <MDInput
                    {...field}
                    type="text"
                    label="Home Text 2 (Lang - Eng)"
                    variant="standard"
                    fullWidth
                  />
                </>
              )}
            />
          </MDBox>
          <MDBox mb={2}>
            <Controller
              name="homeText1Ar"
              control={control}
              render={({ field }) => (
                <>
                  <MDInput
                    {...field}
                    type="text"
                    label="Home Text (Lang - Arabic)"
                    variant="standard"
                    fullWidth
                  />
                </>
              )}
            />
          </MDBox>
          <MDBox mb={2}>
            <Controller
              name="homeText2Ar"
              control={control}
              render={({ field }) => (
                <>
                  <MDInput
                    {...field}
                    type="text"
                    label="Home Text 2 (Lang - Arabic)"
                    variant="standard"
                    fullWidth
                  />
                </>
              )}
            />
          </MDBox>
          <MDBox mb={2}>
            <Controller
              name="summaryText1En"
              control={control}
              render={({ field }) => (
                <>
                  <MDInput
                    {...field}
                    type="text"
                    label="Summary Text (Lang - Eng)"
                    variant="standard"
                    fullWidth
                  />
                </>
              )}
            />
          </MDBox>
          <MDBox mb={2}>
            <Controller
              name="summaryText2En"
              control={control}
              render={({ field }) => (
                <>
                  <MDInput
                    {...field}
                    type="text"
                    label="Summary Text 2 (Lang - Eng)"
                    variant="standard"
                    fullWidth
                  />
                </>
              )}
            />
          </MDBox>
          <MDBox mb={2}>
            <Controller
              name="summaryText1Ar"
              control={control}
              render={({ field }) => (
                <>
                  <MDInput
                    {...field}
                    type="text"
                    label="Summary Text (Lang - Arabic)"
                    variant="standard"
                    fullWidth
                  />
                </>
              )}
            />
          </MDBox>
          <MDBox mb={4}>
            <Controller
              name="summaryText2Ar"
              control={control}
              render={({ field }) => (
                <>
                  <MDInput
                    {...field}
                    type="text"
                    label="Summary Text 2 (Lang - Arabic)"
                    variant="standard"
                    fullWidth
                  />
                </>
              )}
            />
          </MDBox>
        </MDBox>
        {/* <MDBox mb={2}>
            <Controller
              name="themeImage"
              control={control}
              render={({ field }) => (
                <MDInput {...field} type="file" label="Theme Image" variant="standard" fullWidth />
              )}
            />
          </MDBox> */}
        <MDBox mt={3} sx={{ Padding: 2 }}>
          <MDTypography>Campaign Image & Status</MDTypography>
          <MDBox mb={2} mt={2}>
            <FileUpload onChange={(file) => setImageSelected(file)}></FileUpload>
          </MDBox>
          <MDBox mb={2} mt={2}>
            <FormControl fullWidth>
              <InputLabel htmlFor="campaign-status">Campaign Status</InputLabel>
              <Controller
                name="isActive"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      label="Campaign Status"
                      variant="standard"
                      fullWidth
                      error={!!errors.isActive}
                      // helperText={
                      //   errors.isActive && (
                      //     <span style={{ color: "red" }}>{errors.isActive.message}</span>
                      //   )
                      // }
                    >
                      <MenuItem value="true">Active</MenuItem>
                      <MenuItem value="false">Inactive</MenuItem>
                    </Select>
                    <Box mt={1}>
                      <FormHelperText error={!!errors.isActive}>
                        {errors.isActive && (
                          <span style={{ color: "red" }}>{errors.isActive.message}</span>
                        )}
                      </FormHelperText>
                    </Box>
                  </>
                )}
                rules={{ required: "Campaign Status is required" }}
              />
            </FormControl>
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" ml={-1}></MDBox>
        <MDBox mt={4} mb={1} sx={{ display: "flex", justifyContent: "center" }}>
          <MDButton type="submit" variant="gradient" color="info" fullWidth sx={{ width: "12rem" }}>
            Save
          </MDButton>
        </MDBox>
        <MDBox mt={3} mb={1} textAlign="center"></MDBox>
      </MDBox>
      {/* </MDBox> */}
    </>
    // </Card>
  );
}

AddNewCampaig.propTypes = {
  setShowNewComponent: PropTypes.func.isRequired,
  // ... other prop types
};
export default AddNewCampaig;
