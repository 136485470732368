/* eslint-disable react/prop-types */
import React from "react";
import { Card, CardContent, Typography, Grid, Chip } from "@mui/material";

// eslint-disable-next-line react/prop-types
const CampaignSummaryCard = ({ campaignSummaryData }) => {
  const {
    campaignName = "Na",
    totalSteps = "00",
    achievedTargetSteps = "00",
    totalSG = "00",
  } = campaignSummaryData ?? {};
  return (
    <Card
      elevation={3}
      style={{ minWidth: 275, borderRadius: 16, boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}
    >
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} mt={2}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold", fontSize: 18 }}>
              Campaign Name: {campaignName.toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Achieved Steps: {achievedTargetSteps}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">Total Steps: {totalSteps}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">Total Surgery: {totalSG}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CampaignSummaryCard;
