import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoutes = (prop) => {
  const { Components } = prop;
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token === null) {
      navigate("/sign-in");
    }
  }, []);

  return <>{Components}</>;
};

export default ProtectedRoutes;
