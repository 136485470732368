import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { useParams, useSearchParams } from "react-router-dom";
import UserSummaryCard from "./UserSummaryCard";
import { apiPost } from "utils/AxiosService";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useMaterialUIController, setSnackbarState, setLoaderState } from "context";

export default function UserSummary() {
  const [controller, dispatch] = useMaterialUIController();
  const param = useParams();
  const [searchParams] = useSearchParams();
  const [userSummaryData, setUserSummaryData] = useState([]);
  const phone = searchParams.get("phone");
  useEffect(() => {
    getAllSummaryByCampaignId();
  }, [phone]);

  const getAllSummaryByCampaignId = async () => {
    const requestData = { phone: `${phone.replaceAll(" ", "+")}` };
    console.log(phone);
    //setLoaderState(dispatch, true);
    try {
      const apiResponse = await apiPost("/home/getSummary", requestData);
      setUserSummaryData(apiResponse?.data);
      //setLoaderState(dispatch, false);
      setSnackbarState(dispatch, {
        open: true,
        message: apiResponse?.message,
        severity: "success",
      });
    } catch (error) {
      //setLoaderState(dispatch, false);
      setSnackbarState(dispatch, {
        open: true,
        message: error?.message,
        severity: "error",
      });
    }
  };
  //   const campaignSummary = {
  //     campaignName: "Example Campaign",
  //     totalSteps: 1000,
  //     achievedTargetSteps: 750,
  //     totalSG: 500,
  //   };
  return (
    <DashboardLayout>
      <DashboardNavbar style={{ zIndex: 300 }} />
      <MDBox pt={1} mt={5} mb={5} sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ width: "100%" }}>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            <MDTypography variant="h6" color="white">
              User Summary
            </MDTypography>
          </MDBox>
          {/* {userSummaryData ? ( */}
          <UserSummaryCard userSummaryData={userSummaryData} />
          {/* // ) : (
          //   <MDBox>
          //     <MDTypography variant="h4" fontWeight="medium" color="black" mt={1}>
          //       No data
          //     </MDTypography>
          //   </MDBox>
          // )} */}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
