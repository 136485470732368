/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import AddNewCampaign from "layouts/Campaigns/addCampaigns/AddNewCampaign";
//import { useAppState } from "utils/context/appStateContext";

function NewCampaignAdd() {
  //   const [totalItems, setTotalItems] = useState(0);
  //   const [columns, setColumns] = useState([]);
  //   const [rows, setRows] = useState([]);
  //   const [totalPage, setToalPage] = useState([]);
  //   const [pageNo, setPageNo] = useState(0);
  //   const { id } = authorsTableData();

  //   const fetchIdRef = useRef(0);

  //   useEffect(() => {
  //     fetchData(0, 10);
  //   }, []);

  //   console.log(id);

  //   useEffect(() => {}, []);

  //   const fetchData = useCallback((pageIndex = 0, pageSize = 10) => {
  //     const fethId = ++fetchIdRef.current;
  //     setTimeout(async () => {
  //       if (fethId === fetchIdRef.current) {
  //         try {
  //           const apiData = await apiGet(`/campaign/get?page=${pageIndex}&size=${pageSize}`);
  //           setTotalItems(apiData?.data?.totalElements);
  //           const { columns, rows } = authorsTableData(apiData);
  //           setColumns(columns);
  //           setRows(rows);
  //           setToalPage(Math.ceil(apiData?.data?.totalElements / pageSize));
  //           setPageNo(pageIndex);
  //         } catch (error) {
  //           console.error("Error fetching data:", error);
  //         }
  //       }
  //     }, 100);
  //   }, []);

  //   const ColorButton = styled(Button)(({ theme }) => ({
  //     color: theme.palette.getContrastText(purple[500]),
  //     backgroundColor: blue[500],
  //     "&:hover": {
  //       backgroundColor: blue[700],
  //     },
  //   }));
  //   const [all, setAll] = useState("");

  //   const handleChange = (event) => {
  //     setAll(event.target.value);
  //   };

  //   const [showNewComponent, setShowNewComponent] = useState(false);

  //   const handleNewCampaignClick = () => {
  //     setShowNewComponent(true);
  //   };
  //   const handleCampaignNavigate = () => {
  //     setShowNewComponent(false);
  //   };
  //   const [openPopup, setOpenPopUp] = useState(true);

  return (
    <DashboardLayout>
      <DashboardNavbar style={{ zIndex: 300 }} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <MDTypography variant="h6" color="white">
                  Add New Campaigns
                </MDTypography>
              </MDBox>

              <MDBox pt={1} mt={5} mb={5} sx={{ display: "flex", justifyContent: "center" }}>
                <AddNewCampaign />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default NewCampaignAdd;
