import axios from "axios";
import { getLocalStorageItem, clearLocalStorage } from "./localStorage";
import { openSwal } from "./SwalUtill";

// Create an instance of Axios with a base URL and other configurations if needed
const apiService = axios.create({
  baseURL: `${window.location.origin}/api/v1`,
});

// Add a request interceptor
apiService.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    // Perform tasks before the request is sent, such as adding headers
    config.headers.Authorization = token ? `Bearer ${token}` : null;
    return config;
  },
  (error) => {
    // Handle errors that occur during the request
    return Promise.reject(error);
  }
);

// Add a response interceptor
apiService.interceptors.response.use(
  async (response) => {
    // Handle successful responses
    if (response.data && response?.data?.code) {
      logoutUser(response?.data?.code);
    }
    return response.data;
  },
  (error) => {
    if (error && error.response && error.response.status) {
      logoutUser(error.response.status);
    }
    return Promise.reject(error);
  }
);

const logoutUser = async (status) => {
  let result;
  switch (status) {
    case 401:
      result = await openAuthPopup("Token Expired");
      break;
    case 403:
      result = await openAuthPopup("Access Denied");
      break;
    case 409:
      result = await openAuthPopup("Concurrent Session Detected");
      break;
    default:
      result = null;
  }

  if (!result) {
    return;
  }
  rediectToSignIn();
};

const openAuthPopup = async (title, message = "Please Sign-In again to continue") => {
  return openSwal({
    icon: "error",
    title: title,
    text: message,
    allowEscapeKey: false,
    allowOutsideClick: false,
    confirmButtonText: "Okay",
  });
};

const rediectToSignIn = () => {
  clearLocalStorage("token");
  clearLocalStorage("resfreshToken");
  if (window.location.pathname !== "sign-in") {
    setTimeout(() => {
      window.location = "/sign-in";
    }, 100);
  }
};

// Now you can use the 'api' instance to make HTTP requests with the defined interceptors
export default apiService;
