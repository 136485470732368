import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const CustomizedSnackbars = (prop) => {
  const { open, message, setOpen, severity } = prop;
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        handleClose();
      }, 10 * 1000);
    }
  }, [open]);

  return (
    <div>
      <Snackbar open={open} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={handleClose} severity={severity} variant="filled" sx={{ width: "100%" }}>
          <p style={{ color: "white" }}>{message}</p>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CustomizedSnackbars;
