/* eslint-disable react/prop-types */
import React from "react";
import { Card, CardContent, Typography, Grid, Chip } from "@mui/material";
import { format } from "date-fns";

// eslint-disable-next-line react/prop-types
const UserLogsCard = ({ userLog }) => {
  const {
    model = "Na",
    osVersion = "Na",
    appVersion = "Na",
    deviceType = "Na",
    entryDate = "Na",
  } = userLog ?? {};
  console.log(userLog);
  console.log("getting model" + model);
  const moment = require("moment");
  return (
    <Card
      elevation={3}
      style={{ minWidth: 275, borderRadius: 16, boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}
    >
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} mt={2}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold", fontSize: 18 }}>
              Model Name: {model.toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Os Version: {osVersion}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">App Version: {appVersion}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">Device Type: {deviceType}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              Entry Date: {moment.utc(entryDate).format("MMMM DD, YYYY")}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UserLogsCard;
