// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Dashboard components
import MDTypography from "components/MDTypography";
import { useState } from "react";
import { apiGet } from "utils/AxiosService";
import { useEffect } from "react";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import GroupIcon from "@mui/icons-material/Group";
import { useMaterialUIController } from "context";
import { setLoaderState } from "context";

function Dashboard() {
  const [controller, dispatch] = useMaterialUIController();
  const [allCampaigns, setAllCampaigns] = useState("Current Campaign");
  const [data, setData] = useState();
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: { label: "steps", data: [] },
  });

  const [participantsChartData, setParticipantsChartData] = useState({
    labels: [],
    datasets: { label: "participants", data: [] },
  });

  const handleChange = (event) => {
    setAllCampaigns(event.target.value);
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = async () => {
    try {
      setLoaderState(dispatch, true);
      const dashboardResponse = await apiGet("/home/getDashboard");
      const activityProgressResponse = await apiGet("/home/activityProgress");
      const participantResponse = await apiGet("/home/getParticipants");

      fetchData(dashboardResponse);
      fetchChartData(activityProgressResponse);
      fetchParticipantsChartData(participantResponse);
      setLoaderState(dispatch, false);
    } catch (error) {
      setLoaderState(dispatch, false);
      console.error(error);
    }
  };

  const fetchData = (response) => {
    console.log("Data:", response.data);
    setData(response.data);
  };

  const fetchChartData = (response) => {
    if (response && response.data) {
      const {
        data: { steps },
      } = response;
      const newDataChart = { ...chartData }; // Copy current chartData
      steps.forEach((step) => {
        step.Month_activity.forEach((activity) => {
          const label = `${step.Month_name} ${activity.date}`;
          newDataChart.labels.push(label);
          newDataChart.datasets.data.push(parseInt(activity.total_date_step));
          console.log(parseInt(activity.total_date_step) + "the steps");
        });
      });
      setChartData(newDataChart); // Update chartData
      console.log(chartData.datasets.data + "data we hav");
    }
  };

  const fetchParticipantsChartData = (response) => {
    if (response && response.data) {
      const newStepsData = { ...participantsChartData }; // Copy current participantsChartData
      response?.data?.forEach((step) => {
        const label = `${step.stepDate}`;
        newStepsData.labels.push(label);
        newStepsData.datasets.data.push(parseInt(step.participantCount));
        console.log(parseInt(step.participantCount) + "the participants");
      });
      setParticipantsChartData(newStepsData); // Update participantsChartData
      console.log(participantsChartData.datasets.data + "data we have in 2nd graph");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <MDTypography variant="h6" color="white">
                  Dashboard
                </MDTypography>
              </MDBox>

              {data && (
                <MDBox py={3} px={2} mt={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={4}>
                      <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                          color="primary"
                          icon="person_add"
                          //title="Total Surgeries"
                          count={data.totalSG}
                          percentage={{
                            color: "success",
                            amount: "",
                            label: "Total Surgeries",
                          }}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                          color="primary"
                          icon={<DirectionsWalkIcon />}
                          //title="Total Steps"
                          count={data.totalSteps}
                          percentage={{
                            color: "success",
                            amount: "",
                            label: "Total Steps",
                          }}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                          color="primary"
                          icon={<GroupIcon />}
                          //title="Total Users"
                          count={data.totalUser}
                          percentage={{
                            color: "success",
                            amount: "",
                            label: "Total Users",
                          }}
                        />
                      </MDBox>
                    </Grid>
                    {/* <Grid item xs={12} md={6} lg={3}>
                      <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                          color="primary"
                          icon="person_add"
                          title="Followerssd"
                          count="+91"
                          percentage={{
                            color: "success",
                            amount: "",
                            label: "Just updated",
                          }}
                        />
                      </MDBox>
                    </Grid> */}
                  </Grid>
                </MDBox>
              )}
              <MDTypography
                mt={1}
                ml={2}
                style={{
                  display: "flex",
                  justifyContent: "left",
                  fontSize: "xx-large",
                  fontWeight: 500,
                }}
              >
                {" "}
                Steps Graph
              </MDTypography>
              <MDBox pt={3} mt={2}>
                <ReportsBarChart ml={5} chart={chartData} showTimerIcon={false} />

                <MDTypography
                  mt={1}
                  ml={2}
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    fontSize: "xx-large",
                    fontWeight: 500,
                  }}
                >
                  {" "}
                  Participants Graph
                </MDTypography>
                <MDBox pt={3} mt={2}>
                  <ReportsBarChart ml={5} chart={participantsChartData} showTimerIcon={false} />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Dashboard;
