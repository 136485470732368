/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import { WidthWide } from "@mui/icons-material";
import format from "date-fns/format";
import { Link } from "react-router-dom";
import MDButton from "components/MDButton";

export default function userDataTable(props) {
  const { apiData, handleNavigate, navigateUserSummary } = props;
  const data = apiData;
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "Sr.No", accessor: "srNo", align: "left" },
      { Header: "Name", accessor: "name", WidthWide, align: "left" },
      { Header: "Phone Number", accessor: "phoneNumber", align: "center" },
      { Header: "Date Of Birth", accessor: "dateOfBirth", align: "center" },
      { Header: "Days Participated", accessor: "daysParticipated", align: "center" },
      { Header: "Total Steps", accessor: "totalSteps", align: "center" },
      { Header: "Targets", accessor: "targets", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
      { Header: "Summary", accessor: "summary", align: "center" },
    ],

    rows: (data?.data?.content || []).map((item, index) => ({
      srNo: (
        <MDTypography component="a" variant="h7">
          {index + 1}
        </MDTypography>
      ),
      name: (
        <Link to={`/users/logs/${item.name}?id=${item.id}`}>
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {item.name}
          </MDTypography>
        </Link>
      ),
      phoneNumber: (
        <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
          {item.phone}
        </MDTypography>
      ),
      dateOfBirth: (
        <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
          {format(new Date(item.dob), "yyyy-MM-dd")}
        </MDTypography>
      ),
      daysParticipated: (
        <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
          {item.daysParticipated}
        </MDTypography>
      ),
      totalSteps: (
        <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
          {item.totalSteps}
        </MDTypography>
      ),
      targets: (
        <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
          {item.targets}
        </MDTypography>
      ),
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={item?.status ? "Active" : "InActive"}
            color={item?.status ? "success" : "error"}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      action: (
        <MDButton
          onClick={() => handleNavigate(item)}
          component="a"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          Edit
        </MDButton>
      ),
      summary: (
        <>
          <MDButton size="large" onClick={() => navigateUserSummary(item)}>
            Get Summary
          </MDButton>
        </>
      ),
    })),
  };
}
