import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { apiGet } from "utils/AxiosService";
import { useParams, useSearchParams } from "react-router-dom";
import UserLogsCard from "./UserLogsCard";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
export default function UserLogs() {
  const [userLogs, setUserLogs] = useState([]);
  const param = useParams();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("id");
  console.log(userId);
  useEffect(() => {
    getUserLogs();
  }, [userId]);

  const getUserLogs = async () => {
    const apiResponse = await apiGet("/home/getUserLog/" + userId);
    setUserLogs(apiResponse?.data);
    console.log(apiResponse);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar style={{ zIndex: 300 }} />
      <MDBox pt={1} mt={5} mb={5} sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ width: "100%" }}>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            <MDTypography variant="h6" color="white">
              User Logs
            </MDTypography>
          </MDBox>
          {userLogs.map((userLog, index) => (
            <UserLogsCard key={index} userLog={userLog} />
          ))}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
