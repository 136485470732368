// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { purple, blue } from "@mui/material/colors";
import { apiGet } from "utils/AxiosService";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import { useCallback, useEffect, useRef, useState } from "react";
import AddNewCampaign from "layouts/Campaigns/addCampaigns/AddNewCampaign";
import { useNavigate } from "react-router-dom";
import { useMaterialUIController, setSnackbarState, setLoaderState } from "context";

function Tables() {
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  const [totalItems, setTotalItems] = useState(0);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [totalPage, setToalPage] = useState([]);
  const [pageNo, setPageNo] = useState(0);

  const fetchIdRef = useRef(0);

  useEffect(() => {
    fetchData(0, 10);
  }, []);

  const handleEditClick = (item) => {
    navigate(`/campaigns/edit/${item.campaignTitle}?id=${item.campaignId}`);
  };

  const navigateCampaignSummary = (item) => {
    navigate(`/campaigns/summary/${item.campaignTitle}?id=${item.campaignId}`);
  };

  const fetchData = useCallback((pageIndex = 0, pageSize = 10) => {
    const fethId = ++fetchIdRef.current;
    setTimeout(async () => {
      if (fethId === fetchIdRef.current) {
        try {
          setLoaderState(dispatch, true);
          const apiData = await apiGet(`/campaign/get?page=${pageIndex}&size=${pageSize}`);
          setTotalItems(apiData?.data?.totalElements);
          const { columns, rows } = authorsTableData({
            apiData,
            handleEditClick,
            navigateCampaignSummary,
          });
          setColumns(columns);
          setRows(rows);
          setToalPage(Math.ceil(apiData?.data?.totalElements / pageSize));
          setPageNo(pageIndex);
          setSnackbarState(dispatch, {
            open: true,
            message: apiData?.message,
            severity: "success",
          });
          setLoaderState(dispatch, false);
          setLoaderState(dispatch, false);
        } catch (error) {
          setLoaderState(dispatch, false);
        }
      }
    }, 100);
  }, []);

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: blue[500],
    "&:hover": {
      backgroundColor: blue[700],
    },
  }));
  const [all, setAll] = useState("");

  const handleChange = (event) => {
    setAll(event.target.value);
  };

  const [showNewComponent, setShowNewComponent] = useState(false);

  const handleNewCampaignClick = () => {
    //setShowNewComponent(true);
    navigate("/add-new-campaign");
  };
  const handleCampaignNavigate = () => {
    setShowNewComponent(false);
  };
  const [openPopup, setOpenPopUp] = useState(true);

  return (
    <DashboardLayout>
      <DashboardNavbar style={{ zIndex: 300 }} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <MDTypography variant="h6" color="white">
                  Campaigns
                </MDTypography>
              </MDBox>
              {showNewComponent ? (
                <MDBox pt={1} mt={5} mb={5} sx={{ display: "flex", justifyContent: "center" }}>
                  <AddNewCampaign handleCampaignNavigate={handleCampaignNavigate} />
                </MDBox>
              ) : (
                <>
                  <>
                    <MDBox mt={3} style={{ display: "flex", justifyContent: "flex-end" }} mr={5}>
                      <ColorButton variant="contained" onClick={() => handleNewCampaignClick()}>
                        New Campaign
                      </ColorButton>
                    </MDBox>
                  </>
                  <MDBox pt={1} mt={2}>
                    {columns && (
                      <DataTable
                        table={{ columns, rows }}
                        isSorted={true}
                        entriesPerPage={true}
                        showTotalEntries={true}
                        totalItems={totalItems}
                        totalPage={totalPage}
                        fetchData={fetchData}
                        pageNo={pageNo}
                      />
                    )}
                  </MDBox>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
