// react-router-dom components
import Grid from "@mui/material/Grid";
import { useSearchParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useForm, Controller } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useParams } from "react-router-dom";

// Images
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CustomizedSnackbars from "layouts/toaster/CustomizedSnackbars";
import { apiGet } from "utils/AxiosService";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { apiPatch } from "utils/AxiosService";
import { useMaterialUIController, setSnackbarState, setLoaderState } from "context";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FileUpload from "components/ImageUpload";
function EditCampaign(props) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [myData, setMyData] = useState();
  const [imgUrl, setImgUrl] = useState("");
  const [imageSelected, setImageSelected] = useState(null);
  const [imgPreview, setImgPreview] = useState(false);
  // eslint-disable-next-line react/prop-types
  const [controller, dispatch] = useMaterialUIController();

  const { editCampaignId } = controller;

  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const formData = new FormData();
    delete data.participants;
    delete data.imageUrl;
    let requestData = {
      ...data,
      // isCompleted: data.isCompleted === "true" ? true : false,
      // isActive: data.isActive === "true" ? true : false,
    };
    for (const key in requestData) {
      if (Object.hasOwnProperty.call(requestData, key)) {
        const value = requestData[key];
        formData.append(key, value);
      }
    }

    if (imageSelected !== null) {
      formData.append("imageFile", imageSelected);
    }
    updateCampaign(formData);
  };

  const updateCampaign = async (requestData) => {
    try {
      setLoaderState(dispatch, true);
      const response = await apiPatch("/campaign/update", requestData);
      if (response.statusCode === 200) {
        setMessage(response.message);
        setLoaderState(dispatch, false);
        setStatus("success");
        setOpen(true);
        setSnackbarState(dispatch, {
          open: true,
          message: response?.message,
          severity: "success",
        });
        navigate("/campaigns");
      } else {
        if (response.statusCode === 400) {
          setMessage(response.message);
          setLoaderState(dispatch, false);
          setStatus("error");
          setOpen(true);
        }
      }
    } catch (error) {
      console.log(error.message);
      setMessage(error.message);
      setLoaderState(dispatch, false);
      setStatus("error");
      setOpen(true);
    }
  };
  const param = useParams();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    setTimeout(() => {
      getCampaignId();
    }, 100);
  }, []);
  const getCampaignId = async () => {
    setLoaderState(dispatch, true);
    const id = searchParams.get("id");
    //if (!id || isNaN(Number(id))) return;
    const response = await apiGet("/campaign/" + id);
    if (response.statusCode === 200) {
      setMyData(response);
      reset(response.data);
      setLoaderState(dispatch, false);
      setSnackbarState(dispatch, {
        open: true,
        message: response?.message,
        severity: "info",
      });
    } else {
      setLoaderState(dispatch, false);
      setSnackbarState(dispatch, {
        open: true,
        message: "Campaigin id not found.",
        severity: "error",
      });
      navigate("/campaigns");
    }
  };

  const handlePreviewImage = () => {
    setImgPreview(true);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar style={{ zIndex: 300 }} />
      <MDBox pt={1} mt={5} mb={5} sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card sx={{ width: "100%" }}>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <MDTypography variant="h6" color="white">
                  Edit Campaign
                </MDTypography>
              </MDBox>
              <CustomizedSnackbars
                open={open}
                setOpen={setOpen}
                message={message}
                severity={status}
              />
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
                  <MDBox mt={3} sx={{ Padding: 2, borderBottom: 3 }}>
                    <MDTypography style={{ fontWeight: "600", fontSize: "x-large" }}>
                      Titile & Steps
                    </MDTypography>
                    <MDBox mb={4} mt={2}>
                      <InputLabel>Campaign Titile</InputLabel>
                      <Controller
                        name="campaignTitle"
                        control={control}
                        render={({ field }) => (
                          <>
                            <MDInput
                              {...field}
                              type="text"
                              variant="standard"
                              fullWidth
                              error={!!errors.campaignTitle}
                              helperText={
                                errors.campaignTitle && (
                                  <span style={{ color: "red" }}>
                                    {errors.campaignTitle.message}
                                  </span>
                                )
                              }
                            />
                          </>
                        )}
                        rules={{ required: "Campaign Title is required" }}
                      />
                    </MDBox>
                    <MDBox mb={4}>
                      <InputLabel>Campaign Titile (Lang-Arabic)</InputLabel>
                      <Controller
                        name="arabicCampaignTitle"
                        control={control}
                        render={({ field }) => (
                          <>
                            <MDInput {...field} type="text" variant="standard" fullWidth />
                          </>
                        )}
                      />
                    </MDBox>
                    <MDBox mb={4}>
                      <InputLabel>Total Steps</InputLabel>
                      <Controller
                        name="totalSteps"
                        control={control}
                        render={({ field }) => (
                          <>
                            <MDInput
                              {...field}
                              type="number"
                              variant="standard"
                              fullWidth
                              error={!!errors.totalSteps}
                              helperText={
                                errors.totalSteps && (
                                  <span style={{ color: "red" }}>{errors.totalSteps.message}</span>
                                )
                              }
                            />
                          </>
                        )}
                        rules={{ required: "Campaign Target is required" }}
                      />
                    </MDBox>
                    <MDBox mb={4}>
                      <InputLabel>Target4SG </InputLabel>
                      <Controller
                        name="target4SG"
                        control={control}
                        render={({ field }) => (
                          <>
                            <MDInput
                              {...field}
                              type="number"
                              variant="standard"
                              fullWidth
                              error={!!errors.target4SG}
                              helperText={
                                errors.target && (
                                  <span style={{ color: "red" }}>{errors.target.message}</span>
                                )
                              }
                            />
                          </>
                        )}
                        rules={{ required: "Steps to Achieve is required" }}
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox mt={3} sx={{ Padding: 2, borderBottom: 3 }}>
                    <MDTypography style={{ fontWeight: "600", fontSize: "x-large" }}>
                      Campaign Description
                    </MDTypography>
                    <MDBox mb={4} mt={2}>
                      <InputLabel>Camapaign Description (Lang - Eng)</InputLabel>
                      <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                          <>
                            <MDInput
                              {...field}
                              type="text"
                              variant="standard"
                              fullWidth
                              error={!!errors.description}
                              helperText={
                                errors.description && (
                                  <span style={{ color: "red" }}>{errors.description.message}</span>
                                )
                              }
                            />
                          </>
                        )}
                        rules={{ required: "Campaign Description is required" }}
                      />
                    </MDBox>
                    <MDBox mb={4}>
                      <InputLabel>Camapaign Description 2 (Lang - Eng)</InputLabel>
                      <Controller
                        name="description2"
                        control={control}
                        render={({ field }) => (
                          <>
                            <MDInput {...field} type="text" variant="standard" fullWidth />
                          </>
                        )}
                      />
                    </MDBox>
                    <MDBox mb={4}>
                      <InputLabel>Camapaign Description (Lang - Arabic)</InputLabel>
                      <Controller
                        name="arabicDescription1"
                        control={control}
                        render={({ field }) => (
                          <>
                            <MDInput {...field} type="text" variant="standard" fullWidth />
                          </>
                        )}
                      />
                    </MDBox>
                    <MDBox mb={4}>
                      <InputLabel>Camapaign Description (Lang - Arabic)</InputLabel>
                      <Controller
                        name="arabicDescription2"
                        control={control}
                        render={({ field }) => (
                          <>
                            <MDInput {...field} type="text" variant="standard" fullWidth />
                          </>
                        )}
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox mt={3} sx={{ Padding: 2, borderBottom: 3 }}>
                    <MDTypography style={{ fontWeight: "600", fontSize: "x-large" }}>
                      Home Text & Summary
                    </MDTypography>
                    <MDBox mb={4} mt={2}>
                      <InputLabel>Home Text (Lang - Eng)</InputLabel>
                      <Controller
                        name="homeText1En"
                        control={control}
                        render={({ field }) => (
                          <>
                            <MDInput {...field} type="text" variant="standard" fullWidth />
                          </>
                        )}
                      />
                    </MDBox>
                    <MDBox mb={4}>
                      <InputLabel>Home Text 2 (Lang - Eng)</InputLabel>
                      <Controller
                        name="homeText2En"
                        control={control}
                        render={({ field }) => (
                          <>
                            <MDInput {...field} type="text" variant="standard" fullWidth />
                          </>
                        )}
                      />
                    </MDBox>
                    <MDBox mb={4}>
                      <InputLabel>Home Text (Lang - Arabic)</InputLabel>
                      <Controller
                        name="homeText1Ar"
                        control={control}
                        render={({ field }) => (
                          <>
                            <MDInput {...field} type="text" variant="standard" fullWidth />
                          </>
                        )}
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <InputLabel>Home Text 2 (Lang - Arabic)</InputLabel>
                      <Controller
                        name="homeText2Ar"
                        control={control}
                        render={({ field }) => (
                          <>
                            <MDInput {...field} type="text" variant="standard" fullWidth />
                          </>
                        )}
                      />
                    </MDBox>
                    <MDBox mb={4}>
                      <InputLabel>Summary Text (Lang - Eng)</InputLabel>
                      <Controller
                        name="summaryText1En"
                        control={control}
                        render={({ field }) => (
                          <>
                            <MDInput {...field} type="text" variant="standard" fullWidth />
                          </>
                        )}
                      />
                    </MDBox>
                    <MDBox mb={4}>
                      <InputLabel>Summary Text 2 (Lang - Eng)</InputLabel>
                      <Controller
                        name="summaryText2En"
                        control={control}
                        render={({ field }) => (
                          <>
                            <MDInput {...field} type="text" variant="standard" fullWidth />
                          </>
                        )}
                      />
                    </MDBox>
                    <MDBox mb={4}>
                      <InputLabel>Summary Text (Lang - Arabic)</InputLabel>
                      <Controller
                        name="summaryText1Ar"
                        control={control}
                        render={({ field }) => (
                          <>
                            <MDInput {...field} type="text" variant="standard" fullWidth />
                          </>
                        )}
                      />
                    </MDBox>
                    <MDBox mb={4}>
                      <InputLabel>Summary Text 2 (Lang - Arabic)</InputLabel>
                      <Controller
                        name="summaryText2Ar"
                        control={control}
                        render={({ field }) => (
                          <>
                            <MDInput {...field} type="text" variant="standard" fullWidth />
                          </>
                        )}
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox mt={3} sx={{ Padding: 2 }}>
                    <MDTypography style={{ fontWeight: "600", fontSize: "x-large" }}>
                      Campaign -(Status, IsCompleted, Summary Flag)
                    </MDTypography>
                    <MDBox mb={4} mt={2}>
                      <InputLabel>Campaign Status</InputLabel>
                      <FormControl fullWidth>
                        <Controller
                          name="isActive"
                          control={control}
                          defaultValue={control?._defaultValues?.isActive || false} // Set a default value if not available
                          render={({ field }) => (
                            <Select
                              {...field}
                              variant="standard"
                              fullWidth
                              inputProps={{
                                name: "isActive",
                                id: "uncontrolled-native",
                              }}
                            >
                              <MenuItem value={true}>Active</MenuItem>
                              <MenuItem value={false}>Inactive</MenuItem>{" "}
                              {/* Corrected spelling for "Inactive" */}
                            </Select>
                          )}
                        />
                      </FormControl>
                    </MDBox>
                  </MDBox>

                  <MDBox mb={4}>
                    <InputLabel>Campaign Is-Completed</InputLabel>
                    <FormControl fullWidth>
                      <Controller
                        name="isCompleted"
                        control={control}
                        defaultValue={control?._formValues?.isCompleted || false}
                        render={({ field }) => (
                          <Select
                            {...field}
                            variant="standard"
                            fullWidth
                            inputProps={{
                              name: "isCompleted",
                              id: "uncontrolled-native",
                            }}
                          >
                            <MenuItem value={true}>Completed</MenuItem>
                            <MenuItem value={false}>In-Complete</MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>
                  </MDBox>
                  <MDBox mb={4}>
                    <InputLabel>Summary Flag</InputLabel>
                    <FormControl fullWidth>
                      <Controller
                        name="summaryFlag"
                        control={control}
                        defaultValue={control?._formValues?.summaryFlag || false}
                        render={({ field }) => (
                          <Select
                            {...field}
                            variant="standard"
                            fullWidth
                            inputProps={{
                              name: "summaryFlag",
                              id: "uncontrolled-native",
                            }}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>
                  </MDBox>

                  <MDBox>
                    <FileUpload
                      onChange={(file) => setImageSelected(file)}
                      url={myData?.data?.imageUrl}
                    ></FileUpload>
                  </MDBox>

                  {/* <MDBox mb={2} mt={2}>
                <InputLabel htmlFor="campaign-status">Campaign Image</InputLabel>
                <Controller
                  name="imageFile"
                  control={control}
                  //defaultValue={iamgeUrl}
                  render={({ field }) => (
                    <>
                      <input
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        type="file"
                        accept="image/*"
                        //onChange={onImageSelect}
                      />
                    </>
                  )}
                />
              </MDBox> */}

                  <MDBox display="flex" alignItems="center" ml={-1}></MDBox>
                  <MDBox mt={4} mb={1} sx={{ display: "flex", justifyContent: "center" }}>
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="info"
                      fullWidth
                      sx={{ width: "12rem" }}
                    >
                      Save
                    </MDButton>
                  </MDBox>
                  <MDBox mt={3} mb={1} textAlign="center"></MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
export default EditCampaign;
