// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import EditNoteIcon from "@mui/icons-material/EditNote";

// Images
import { WidthWide } from "@mui/icons-material";
import MDButton from "components/MDButton";

import { apiPatch } from "utils/AxiosService";
import { openSwal } from "utils/SwalUtill";

export default function data(initData) {
  const { apiData, handleEditClick, navigateCampaignSummary } = initData;
  const data = apiData;

  // const handleEditClick = (item) => {
  //   window.location = "/edit-campaign/" + item.campaignId;
  // };

  // const navigateCampaignSummary = (item) => {
  //   window.location = "/campaign-summary/" + item.campaignId;
  // };

  const handleToggle = (event, id) => {
    console.log(event.target.checked);
    handleDeleteClick(id, event.target.checked);
  };

  const handleIsCompleted = (event, id) => {
    console.log(event.target.checked);
    console.log("inside is completed");
    handleUpdateCompletion(id, event.target.checked);
  };

  const handleUpdateCompletion = async (id, isCompletedStatus) => {
    let requestBody = {
      campaignId: id,
      isCompleted: isCompletedStatus,
    };

    const result = await openSwal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: isCompletedStatus ? "rgb(0, 128, 0)" : "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: isCompletedStatus ? "Completed" : "In-Complete",
    });

    if (!result.isConfirmed) {
      return;
    }
    // Perform API call using axios or your preferred library
    apiPatch("/campaign/updateCompletion", requestBody)
      .then((response) => {
        if (response.statusCode === 200) {
          setMessage(response.message);
          setStatus("success");
          setOpen(true);
          fetchData();
          // navigate("/admin/dashboard/view_all_campaigns");
        } else {
          if (response.statusCode === 400) {
            setMessage(response.message);
            setStatus("error");
            setOpen(true);
          }
        }
        fetchData();
        console.log("API call successful:", response);
      })
      .catch((error) => {
        console.error("API call failed:", error);
        // Handle errors or show an error message
      });
  };

  const handleDeleteClick = async (id, status) => {
    let requestBody = {
      campaignId: id,
      isActive: status,
    };

    const result = await openSwal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: status ? "rgb(0, 128, 0)" : "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: status ? "Active" : "InActive",
    });

    if (!result.isConfirmed) {
      return;
    }
    // Perform API call using axios or your preferred library
    apiPatch("/campaign/updateStatus", requestBody)
      .then((response) => {
        if (response.statusCode === 200) {
          setMessage(response.message);
          setStatus("success");
          setOpen(true);
          fetchData();
          // navigate("/admin/dashboard/view_all_campaigns");
        } else {
          if (response.statusCode === 400) {
            setMessage(response.message);
            setStatus("error");
            setOpen(true);
          }
        }
        fetchData();
        console.log("API call successful:", response);
      })
      .catch((error) => {
        console.error("API call failed:", error);
        // Handle errors or show an error message
      });
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const label2 = { inputProps: { "aria-label": "Switch demo2" } };

  return {
    columns: [
      { Header: "Sr.No", accessor: "srNo", align: "left" },
      { Header: "Campaign Title", accessor: "campaignTitle", WidthWide, align: "left" },
      {
        Header: "Campaign Title (lang - Arabic)",
        accessor: "arabicCampaignTitle",
        WidthWide,
        align: "left",
      },
      { Header: "Participants", accessor: "participants", align: "center" },
      { Header: "Steps", accessor: "steps", align: "center" },
      { Header: "Targets", accessor: "targets", align: "center" },
      { Header: "Steps:Target ratio", accessor: "stepsTargetRatio", align: "center" },
      { Header: "Campaign Status", accessor: "campaignStatus", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
      { Header: "Summary Status", accessor: "summary", align: "center" },
    ],
    // rows: [],
    rows: (data?.data?.content || []).map((item, index) => ({
      srNo: (
        <MDTypography component="a" variant="h7">
          {index + 1}
        </MDTypography>
      ),
      campaignTitle: (
        <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
          {item.campaignTitle}
        </MDTypography>
      ),
      arabicCampaignTitle: (
        <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
          {item.arabicCampaignTitle}
        </MDTypography>
      ),
      participants: (
        <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
          {item.participants}
        </MDTypography>
      ),
      steps: (
        <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
          {item.totalSteps}
        </MDTypography>
      ),
      targets: (
        <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
          {item.achievedTargetSteps}
        </MDTypography>
      ),
      stepsTargetRatio: (
        <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
          {item.target4SG}
        </MDTypography>
      ),
      campaignStatus: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={item.isActive ? "Active" : "Inactive"}
            color={item.isActive ? "success" : "error"}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      action: (
        <>
          <MDButton size="large" onClick={() => handleEditClick(item)}>
            <EditNoteIcon />
          </MDButton>
        </>
      ),
      summary: (
        <>
          {item.summaryFlag && (
            <MDButton size="large" onClick={() => navigateCampaignSummary(item)}>
              Get Summary
            </MDButton>
          )}
        </>
      ),
    })),
  };
}
