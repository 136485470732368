// react-router-dom components
import Grid from "@mui/material/Grid";
import { useSearchParams } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useForm, Controller } from "react-hook-form";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useParams } from "react-router-dom";
// Authentication layout components

// Images
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CustomizedSnackbars from "layouts/toaster/CustomizedSnackbars";

import { apiGet } from "utils/AxiosService";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import NativeSelect from "@mui/material/NativeSelect";
import { apiPut } from "utils/AxiosService";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useMaterialUIController, setSnackbarState, setLoaderState } from "context";

function EditUser(props) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [myData, setMyData] = useState();
  // eslint-disable-next-line react/prop-types
  const { rowData, setOpenPopUp, fetchData } = props;
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    let requestData = {
      id: data.id,
      name: data.name,
      phone: data.phone,
      dob: new Date(data.dob).toISOString().split("T")[0],
      gender: data.gender === "MALE" ? 0 : 1,
      isClient: data.isClient,
      status: data.status,
    };
    updateUser(requestData);
  };

  const updateUser = async (requestData) => {
    setLoaderState(dispatch, true);
    try {
      const apiData = await apiPut("/user/update", requestData);
      if (apiData.statusCode === 200) {
        setMessage(apiData.message);
        setStatus("success");
        setOpen(true);
        setSnackbarState(dispatch, {
          open: true,
          message: apiData?.message,
          severity: "success",
        });
        setLoaderState(dispatch, false);
        navigate("/users");
      } else {
        if (apiData.statusCode === 400) {
          setMessage(apiData.message);
          setStatus("error");
          setOpen(true);
          setLoaderState(dispatch, false);
        }
      }
    } catch (error) {
      setMessage(error.message);
      setStatus("error");
      setOpen(true);
      setSnackbarState(dispatch, {
        open: true,
        message: error?.message,
        severity: "error",
      });
    }
  };
  const param = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  useEffect(() => {
    getUserById();
  }, [id]);
  const getUserById = async () => {
    setLoaderState(dispatch, true);
    const apiData = await apiGet("/user/" + id);
    setMyData(apiData);
    reset(apiData.data);

    setSnackbarState(dispatch, {
      open: true,
      message: apiData?.message,
      severity: "info",
    });
    setLoaderState(dispatch, false);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <DashboardLayout>
      <DashboardNavbar style={{ zIndex: 300 }} />
      <MDBox pt={1} mt={5} mb={5} sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card sx={{ width: "100%" }}>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <MDTypography variant="h6" color="white">
                  Edit User
                </MDTypography>
              </MDBox>
              <CustomizedSnackbars
                open={open}
                setOpen={setOpen}
                message={message}
                severity={status}
              />
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
                  <MDBox mb={2}>
                    <InputLabel>Name</InputLabel>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <>
                          <MDInput
                            {...field}
                            type="text"
                            placeholder="username"
                            variant="standard"
                            fullWidth
                            error={!!errors.name}
                            helperText={
                              errors.name && (
                                <span style={{ color: "red" }}>{errors.name.message}</span>
                              )
                            }
                          />
                        </>
                      )}
                      rules={{ required: "Campaign Title is required" }}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <InputLabel>Date Of Birth</InputLabel>
                    <Controller
                      name="dob"
                      control={control}
                      render={({ field }) => {
                        // Assuming `dob` is a string received from your API response
                        const dateValue = field.value ? new Date(field.value) : null;

                        // Check if dateValue is a valid date
                        const isValidDate =
                          !isNaN(dateValue) && dateValue !== null && dateValue !== "Invalid Date";

                        // Format the date if it's valid
                        const formattedDate = isValidDate
                          ? dateValue.toISOString().split("T")[0]
                          : "";

                        return (
                          <>
                            <MDInput
                              {...field}
                              type="date"
                              value={formattedDate ? formattedDate : ""}
                              placeholder="Date Of Birth"
                              variant="standard"
                              fullWidth
                              error={!!errors.dob}
                              helperText={
                                errors.dob && (
                                  <span style={{ color: "red" }}>{errors.dob.message}</span>
                                )
                              }
                            />
                          </>
                        );
                      }}
                      rules={{ required: "User Dob is required" }}
                    />
                  </MDBox>

                  <MDBox mb={2}>
                    <InputLabel>Phone Number</InputLabel>
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field }) => {
                        // Assuming `phone` is a string received from your API response
                        const formattedPhone = field?.value?.replace("+", "");

                        return (
                          <>
                            <MDInput
                              {...field}
                              type="number"
                              value={formattedPhone ? `${formattedPhone}` : ""}
                              placeholder="Phone Number"
                              variant="standard"
                              fullWidth
                              error={!!errors.phone}
                              helperText={
                                errors.phone && (
                                  <span style={{ color: "red" }}>{errors.phone.message}</span>
                                )
                              }
                            />
                          </>
                        );
                      }}
                      rules={{ required: "Phone Number is required" }}
                    />
                  </MDBox>

                  <MDBox mb={2}>
                    <InputLabel>Gender</InputLabel>
                    <FormControl fullWidth>
                      <Controller
                        name="gender"
                        control={control}
                        defaultValue={
                          control?._formValues?.gender ? control._formValues.gender : "FEMALE"
                        }
                        render={({ field }) => (
                          <Select
                            {...field}
                            inputProps={{
                              name: "gender",
                              id: "uncontrolled-native",
                            }}
                          >
                            <MenuItem value="MALE">Male</MenuItem>
                            <MenuItem value="FEMALE">Female</MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>
                  </MDBox>

                  <MDBox mb={2}>
                    <InputLabel>Is Client</InputLabel>
                    <FormControl fullWidth>
                      <Controller
                        name="isClient"
                        control={control}
                        defaultValue={
                          control?._formValues?.isClient ? control._formValues.isClient : false
                        }
                        render={({ field }) => (
                          <Select
                            {...field}
                            inputProps={{
                              name: "isClient",
                              id: "uncontrolled-native",
                            }}
                          >
                            <MenuItem value={true}>Active Client</MenuItem>
                            <MenuItem value={false}>In-Active Client</MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>
                  </MDBox>
                  <MDBox mb={2}>
                    <InputLabel mb={2}>Status</InputLabel>
                    <FormControl fullWidth>
                      <Controller
                        name="status"
                        control={control}
                        defaultValue={
                          control?._formValues?.status ? control._formValues.status : false
                        }
                        render={({ field }) => (
                          <Select
                            {...field}
                            inputProps={{
                              name: "status",
                              id: "uncontrolled-native",
                            }}
                          >
                            <MenuItem value={true}>Active User</MenuItem>
                            <MenuItem value={false}>In-Active User</MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>
                  </MDBox>
                  <MDBox mt={4} mb={1} sx={{ display: "flex", justifyContent: "center" }}>
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="info"
                      fullWidth
                      sx={{ width: "12rem" }}
                    >
                      Save
                    </MDButton>
                  </MDBox>
                  <MDBox display="flex" alignItems="center" ml={-1}></MDBox>

                  <MDBox mt={3} mb={1} textAlign="center"></MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditUser;
