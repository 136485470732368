import React, { createContext, useReducer, useContext } from "react";

// Initial state for the global state
const initialState = {
  selectedItem: null,
};

// Action types
const SET_SELECTED_ITEM = "SET_SELECTED_ITEM";

// Reducer function
const appReducer = (state, action) => {
  switch (action.type) {
    case SET_SELECTED_ITEM:
      return { ...state, selectedItem: action.payload };
    default:
      return state;
  }
};

// Create context and provide the state and dispatch function
const AppStateContext = createContext();

// eslint-disable-next-line react/prop-types
export const AppStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  return (
    <AppStateContext.Provider value={{ state, dispatch }}>{children}</AppStateContext.Provider>
  );
};

// Custom hook to access the context
export const useAppState = () => {
  const context = useContext(AppStateContext);
  if (!context) {
    throw new Error("useAppState must be used within an AppStateProvider");
  }
  return context;
};
