/* eslint-disable react/prop-types */
import React from "react";
import { Card, CardContent, Typography, Grid, Chip } from "@mui/material";

// eslint-disable-next-line react/prop-types
const UserSummaryCard = ({ userSummaryData }) => {
  const { totalCampaignSG = "00", userSG = "00", userSteps = "00" } = userSummaryData ?? {};

  return (
    <Card elevation={3} style={{ minWidth: 275 }}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Total Campaign SG: {totalCampaignSG} </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">User SG: {userSG}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">User Steps: {userSteps}</Typography>
          </Grid>
          {/* <Grid item xs={6}>
            <Typography variant="body1">Surgery: {totalSG}</Typography>
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UserSummaryCard;
