import apiService from "./ApiService";

export const apiGet = async (endpoint, params = {}) => {
  try {
    const response = await apiService.get(endpoint, { params });
    return response;
  } catch (error) {
    console.error("Error in GET request:", error);
    return error?.response?.data;
  }
};

export const apiPost = async (endpoint, data) => {
  try {
    return await apiService.post(endpoint, data);
  } catch (error) {
    console.error("Error in POST request:", error);
    return error?.response?.data;
  }
};

export const apiPut = async (endpoint, data) => {
  try {
    const response = await apiService.put(endpoint, data);
    return response;
  } catch (error) {
    console.error("Error in PUT request:", error);
    return error?.response?.data;
  }
};

export const apiPatch = async (endpoint, data) => {
  try {
    const response = await apiService.patch(endpoint, data);
    return response;
  } catch (error) {
    console.error("Error in PATCH request:", error);
    return error?.response?.data;
  }
};

export const apiDelete = async (endpoint) => {
  try {
    const response = await apiService.delete(endpoint);
    return response.data;
  } catch (error) {
    console.error("Error in DELETE request:", error);
    throw error;
  }
};
