import React, { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";

const Loader = (props) => {
  // eslint-disable-next-line react/prop-types
  const { loader } = props;

  useEffect(() => {
    const el = document.getElementById("app");
    if (!loader && el?.classList.contains("active-loader")) {
      el?.classList.remove("active-loader");
    }
    if (loader && !el?.classList.contains("active-loader")) {
      el?.classList.add("active-loader");
    }
  }, [loader]);

  return (
    <>
      {loader && (
        <MDBox
          mt={5}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "blue",
          }}
          className="loader_container"
        >
          <CircularProgress className="loader_content" sx={{ color: "blue" }} />
        </MDBox>
      )}
    </>
  );
};

export default Loader;
