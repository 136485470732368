// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import userDataTable from "./data/usersTableData";
import { useCallback, useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { useRef } from "react";
import { apiGet } from "utils/AxiosService";
import { useNavigate } from "react-router-dom";
import { useMaterialUIController } from "context";
import { setLoaderState } from "context";

function UsersTable() {
  const [controller, dispatch] = useMaterialUIController();
  const [totalItems, setTotalItems] = useState(0);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [totalPage, setToalPage] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const navigate = useNavigate();

  const fetchIdRef = useRef(0);

  const handleNavigate = (item) => {
    navigate(`/users/edit/${item.name}?id=${item.id}`);
  };
  const navigateUserSummary = (item) => {
    navigate(`/users/summary/${item.name}?phone=${item.phone}`);
  };

  useEffect(() => {
    fetchData(0, 10);
  }, []);

  const fetchData = useCallback((pageIndex = 0, pageSize = 10) => {
    const fethId = ++fetchIdRef.current;
    setTimeout(async () => {
      if (fethId === fetchIdRef.current) {
        setLoaderState(dispatch, true);
        try {
          const apiData = await apiGet(`/user/getAll?page=${pageIndex}&size=${pageSize}`);
          setTotalItems(apiData?.data?.totalElements);
          console.log(apiData);
          const { columns, rows } = userDataTable({
            apiData,
            handleNavigate,
            navigateUserSummary,
          });
          setColumns(columns);
          setRows(rows);
          setPageNo(pageIndex);
          setToalPage(Math.ceil(apiData?.data?.totalElements / pageSize));
          setLoaderState(dispatch, false);
        } catch (error) {
          setLoaderState(dispatch, false);
          console.error("Error fetching data:", error);
        }
      }
    }, 10);
  }, []);

  // const { columns, rows } = userDataTable();
  // const { columns: pColumns, rows: pRows } = projectsTableData();
  // const [allCampaigns, setAllCampaigns] = useState("");

  const theme = useTheme();
  const [personName, setPersonName] = useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const names = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <MDTypography variant="h6" color="white">
                  Users
                </MDTypography>
                {/* <MDBox mr={3}>
                  <FormControl sx={{ m: 1, width: "10rem", mt: 3 }}>
                    <Select
                      multiple
                      displayEmpty
                      value={personName}
                      onChange={handleChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Placeholder</em>;
                        }

                        return selected.join(", ");
                      }}
                      MenuProps={MenuProps}
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{ height: "2rem", borderRadius: "2rem" }}
                    >
                      <MenuItem disabled value="">
                        <em>Placeholder</em>
                      </MenuItem>
                      {names.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          style={getStyles(name, personName, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MDBox> */}
              </MDBox>
              <MDBox pt={3}>
                {columns && (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    totalItems={totalItems}
                    totalPage={totalPage}
                    fetchData={fetchData}
                    pageNo={pageNo}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
          {/* <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Projects Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid> */}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default UsersTable;
